<template>
  <div>
    <!-- modal add borne -->
    <v-dialog 
    v-model="modalAddMaterial" 
    @click:outside="closeModalAddMaterial"
    scrollable 
    width="350px">
      <v-card>
        <v-card-title>Ajouter Un Material</v-card-title>
        <v-divider class="ma-0"></v-divider>
        <v-card-text class="pt-6 pb-0">
          <v-form ref="addNewMaterial" class="p-0">
            <v-text-field
              v-model="materialObject.description"
              label="description"
              outlined
              dense
              :rules="[rules.required]"
            />
            <v-select
              v-model="materialObject.unite"
              :items="['U', 'ml']"
              label="unite"
              outlined
              dense
              :rules="[rules.required]"
            />
            <v-text-field
              type="number"
              v-model="materialObject.quantite"
              label="Quantite"
              outlined
              dense
              :rules="[rules.isNumberOrFloat, rules.greaterThanZero]"
            />
            <v-text-field
              v-model="materialObject.PUHT"
              label="PUHT"
              outlined
              dense
              suffix="€"
              :rules="[rules.isNumberOrFloat, rules.greaterThanZero]"
            />
            <v-text-field
              v-model="materialObject.totalHT"
              label="Total HT"
              outlined
              dense
              suffix="€"
              :rules="[rules.isNumberOrFloat, rules.greaterThanZero]"
              disabled
            />
          </v-form>
        </v-card-text>
        <v-divider class="ma-0"></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
          text
          style="color: #198b81;"
          @click="closeModalAddMaterial"
          >
            Annuler
          </v-btn>
          <v-btn
            elevation="0"
            style="
              color: #fff;
              background-color: #198b81;
              border-color: #198b81;
            "
            @click="addNewMaterial"
          >
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mb-4">
      <devisPdfGenerator
        :buttonText="'generer PDF'"
        :buttonBgColor="'#14324f'"
        :buttonTextColor="'#FFFFFF'"
        :devisData="devisCalcul"
      />
    </div>
    <div class="table-wrapper">
      <table>
        <thead>
          <tr
            class="white--text text-center"
            :style="{ backgroundColor: $colors[1] }"
          >
            <th style="width: 50px"></th>
            <th>Désignation</th>
            <th style="width: 80px">Qté</th>
            <th style="width: 45px">U</th>
            <th style="width: 80px">PUHT</th>
            <th style="width: 100px">Total H.T</th>
            <th style="width: 45px"></th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(devisLOTObject, indexLOT) in devisCalcul.resultatSelected"
          >
            <!-- LOT title -->
            <tr>
              <td class="text-center line-number-bold">
                {{ indexLOT + 1 }}
              </td>
              <td class="text-left title">
                <template v-if="auxData.includes(devisLOTObject.title)">
                  <span>{{ devisLOTObject.title }}</span>
                  <v-icon @click="deleteLOT(devisLOTObject)">mdi-delete</v-icon>
                </template>
                <template v-else>
                  <span>{{ devisLOTObject.title }}</span>
                </template>
                <v-icon
                  elevation="0"
                  v-if="!hasSublotsWithSubtitles(devisLOTObject) && ['admin', 'superadmin', 'ADV', 'Chef équipe'].includes(userRole)"
                style="background-color: #45b780; color: #fff;" 
                  class="ml-2"
                  
                @click="openModalAddMaterial(devisLOTObject)">
                  mdi mdi-plus
                </v-icon>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <template
              v-for="(subLOTObject, indexSubLOT) in devisLOTObject.items"
            >
              <!-- material LOT subtitle -->
              <tr v-if="subLOTObject.subtitle">
                <td class="text-center line-number-bold">
                  {{ indexLOT + 1 + "." + (indexSubLOT + 1) }}
                </td>
                <td class="text-left subtitle">
                  {{ subLOTObject.subtitle }}
                  <v-icon
                    elevation="0"
                  style="background-color: #45b780; color: #fff;" 
                    class="ml-2"
                    v-if="['admin', 'superadmin', 'ADV', 'Chef équipe'].includes(userRole)"
                  @click="openModalAddMaterial(devisLOTObject,indexSubLOT)">
                    mdi mdi-plus
                  </v-icon>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <!-- material items -->
              <template
                v-for="(materialObject, indexMaterial) in subLOTObject.items"
              >
                <tr>
                  <td class="text-center line-number">
                    <span>{{ (indexLOT+1) + "." + (indexSubLOT+1) + "." + (indexMaterial+1) }}</span>
                  </td>
                  <td class="text-left">
                    <span class="material">{{
                      materialObject.description
                    }}</span>
                  </td>
                  <td class="text-center line-value">
                    {{ materialObject.quantite }}
                  </td>
                  <td class="text-center line-value">
                    {{ materialObject.unite }}
                  </td>
                  <td class="text-center line-value">
                    {{ to_fixed2LocaleString(materialObject.PUHT) }}
                  </td>
                  <td class="text-center line-value">
                    {{ to_fixed2LocaleString(materialObject.totalHT) }}
                  </td>
                  <td class="text-center line-value">
                    <v-icon v-if="materialObject.aux == true" @click="deleteMaterial(indexLOT, indexSubLOT, indexMaterial)" color="#ff6459">
                    mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
              <!-- sous-total SUB-LOT -->
              <tr v-if="subLOTObject.subtitle">
                <td></td>
                <td class="text-right Poppins-Bold">
                  Sous-total {{ subLOTObject.subtitle }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-center position-relative">
                  <span class="Poppins-Bold">{{ to_fixed2LocaleString(subLOTObject.total) }}</span>
                  <div
                  v-if="['admin', 'superadmin', 'ADV', 'Chef équipe'].includes(userRole)"
                  :class="{ 'tooltip-edit-value': true, active: editingKey == `${indexLOT}-${indexSubLOT}` }"
                  >
                    <v-form :ref="`editSubLOTTotal-${indexLOT}-${indexSubLOT}`">
                      <div
                      v-if="editingKey == `${indexLOT}-${indexSubLOT}`"
                      class="d-flex flex-row"
                      >
                        <v-text-field
                          type="number"
                          v-model="newTotal"
                          outlined
                          dense
                          hide-details
                          style="min-width: 120px; max-width: 120px"
                          :rules="[
                            rules.required,
                            rules.greaterThanZero,
                            // (value) =>
                            //   validateSousTotal(value, indexLOT, indexSubLOT) ||
                            //   'The total sum of all LOTs cannot exceed the set limit of TotalHT.',
                          ]"
                        ></v-text-field>
                        <v-btn
                          class="ml-2"
                          style="color: #fff; background-color: #14324f"
                          @click="
                            cancelEditSousTotalLOT(
                              subLOTObject,
                              `${indexLOT}-${indexSubLOT}`
                            )
                          "
                        >
                          <v-icon dark> mdi-pencil-off-outline </v-icon>
                        </v-btn>
                        <v-btn
                          class="ml-2"
                          style="color: #fff; background-color: #45b780"
                          @click="
                            editSubLOTTotal(subLOTObject, indexLOT, indexSubLOT)
                          "
                        >
                          <v-icon dark> mdi-check-all </v-icon>
                        </v-btn>
                      </div>
                    </v-form>
                  </div>
                </td>
                <td class="text-center">
                  <v-icon
                  v-if="['admin', 'superadmin', 'ADV', 'Chef équipe'].includes(userRole)"
                  @click="toggleEditSousTotalLOT(subLOTObject, `${indexLOT}-${indexSubLOT}`)"
                  >
                    {{
                      editingKey == `${indexLOT}-${indexSubLOT}`
                        ? "mdi-pencil-off-outline"
                        : "mdi-pencil-outline"
                    }}
                  </v-icon>
                </td>
              </tr>
            </template>
            <!-- sous-total LOT -->
            <tr>
              <td></td>
              <td class="text-right Poppins-Bold">
                Sous-total {{ devisLOTObject.title }}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-center position-relative">
                <span class="Poppins-Bold">{{ to_fixed2LocaleString(devisLOTObject.total) }}</span>
                <div
                v-if="['admin', 'superadmin', 'ADV', 'Chef équipe'].includes(userRole)"
                :class="{ 'tooltip-edit-value': true, active: editingKey == `${indexLOT}`}"
                >
                  <v-form
                  v-if="editingKey == `${indexLOT}`"
                  :ref="`editLOTTotal-${indexLOT}`"
                  >
                    <div class="d-flex flex-row">
                      <v-text-field
                        type="number"
                        v-model="newTotal"
                        outlined
                        dense
                        hide-details
                        style="min-width: 120px; max-width: 120px"
                        :rules="[
                          rules.required,
                          rules.greaterThanZero,
                          // (value) =>
                          //   validateTotalLOT(value, indexLOT) ||
                          //   'The total sum of all LOTs cannot exceed the set limit of TotalHT.',
                        ]"
                      ></v-text-field>
                      <v-btn
                        class="ml-2"
                        style="color: #fff; background-color: #14324f"
                        @click="
                          cancelEditSousTotalLOT(devisLOTObject, `${indexLOT}`)
                        "
                      >
                        <v-icon dark> mdi-pencil-off-outline </v-icon>
                      </v-btn>
                      <v-btn
                        class="ml-2"
                        style="color: #fff; background-color: #45b780"
                        @click="editLOTTotal(devisLOTObject, indexLOT)"
                      >
                        <v-icon dark> mdi-check-all </v-icon>
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </td>
              <td class="text-center">
                <v-icon
                v-if="!hasSublotsWithSubtitles(devisLOTObject) && ['admin', 'superadmin', 'ADV', 'Chef équipe'].includes(userRole)"
                @click="toggleEditSousTotalLOT(devisLOTObject, `${indexLOT}`)"
                >
                  {{
                    editingKey == `${indexLOT}`
                      ? "mdi-pencil-off-outline"
                      : "mdi-pencil-outline"
                  }}
                </v-icon>
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <!-- TVA CARD -->
      <div
        v-if="devisCalcul?.tvaResultat"
        class="d-flex flex-row justify-end"
        style="width: 100%; margin-top: 40px"
      >
        <div
          class="pa-4"
          style="
            width: 100%;
            max-width: 450px;
            border: 1px solid rgba(238, 238, 238, 255);
            border-radius: 2px;
            position: relative;
          "
        >
          <span
            style="
              background-color: white;
              z-index: 10;
              position: absolute;
              top: -12px;
            "
            >Devis (EUR)</span
          >
          <span class="d-flex flex-row justify-space-between">
            <p class="mb-0">Total H.T</p>
            <p class="mb-0">
              {{ to_fixed2LocaleString(devisCalcul.tvaResultat.TotalHT) }} €
            </p>
          </span>
          <span class="d-flex flex-row justify-space-between">
            <p class="mb-2">TVA</p>
            <p class="mb-2">
              {{ to_fixed2LocaleString(devisCalcul.tvaResultat.TVA) }} €
            </p>
          </span>
          <span class="d-flex flex-row justify-space-between">
            <h5 class="Poppins-Bold">Total T.T.C</h5>
            <h5 class="Poppins-Bold">
              {{ to_fixed2LocaleString(devisCalcul.tvaResultat.TotalTTC) }} €
            </h5>
          </span>
          <table class="mt-4" style="width: 100%">
            <thead>
              <tr class="text-center">
                <th
                  style="width: 33%; border: 1px solid rgba(238, 238, 238, 255)"
                >
                  %TVA
                </th>
                <th
                  style="width: 33%; border: 1px solid rgba(238, 238, 238, 255)"
                >
                  BASE
                </th>
                <th
                  style="width: 33%; border: 1px solid rgba(238, 238, 238, 255)"
                >
                  Total TVA
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td style="border: 1px solid rgba(238, 238, 238, 255)">
                  {{
                    to_fixed2LocaleString(devisCalcul.tvaResultat.percentTVA)
                  }}
                </td>
                <td style="border: 1px solid rgba(238, 238, 238, 255)">
                  {{
                    to_fixed2LocaleString(devisCalcul.tvaResultat.percentBase)
                  }}
                </td>
                <td style="border: 1px solid rgba(238, 238, 238, 255)">
                  {{
                    to_fixed2LocaleString(
                      devisCalcul.tvaResultat.percentTotalTVA
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import devisPdfGenerator from "./devisPdfGenerator.vue";
export default {
  name: "DevisPresTable",
  components: { devisPdfGenerator },
  props: ["devisCalcul", "auxData"],
  data() {
    return {
      editingKey: null,
      originalTotal: null,
      newTotal: null,

      changedLOTObject: null,
      changedSubLOTIndex: null,

      modalAddMaterial: false,
      materialObject: {
        description: "",
        unite: "U",
        quantite: 1,
        PUHT: 0,
        totalHT: 0,
      },

      // rules
      rules: {
        required: (value) => {
          let valid = false;
          if (typeof value == "number" && Number(value) >= 0) {
            valid = true;
          } else if (typeof value == "string" && value.toString().length > 0) {
            if (
              typeof Number(value) == "number" &&
              Number(value) != NaN &&
              Number(value) < 0
            ) {
              valid = false;
            } else {
              valid = true;
            }
          }
          return valid || "Ce champ est obligatoire.";
        },
        siretLength: (v) =>
          v.length == 14 || "Le siret doit comporter 14 chiffres",
        isString: (value) =>
          !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
        isNumber: (v) =>
          /^[0-9]+$/.test(v) || "Ce champ n'accepte que les chiffres",
        isNumberOrFloat: (value) =>
          /^[0-9]+(\.\d+)?$/.test(value) ||
          "Ce champ n'accepte que les chiffres ou les nombres décimaux valides (ex. 50 ou 50.3)",
        isFloat: (v) =>
          /^-?\d*(\.\d+)?$/.test(v) ||
          "Ce champ doit être un nombre décimal valide(50.3)",
        hasItemSelected: (v) =>
          v.length >= 1 || "Vous devez selectionner aux moins une champ",
        greaterThanZero: (value) =>
          parseFloat(value) > 0 || "La valeur doit être supérieure à zéro.",
        accepteZero: (value) =>
          parseFloat(value) >= 0 || "La valeur doit être supérieure à zéro.",
      },
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole;
    },
  },
  watch: {
    "materialObject.quantite": function (newVal, oldVal) {
      this.updateTotalHT();
    },
    "materialObject.PUHT": function (newVal, oldVal) {
      this.updateTotalHT();
    },
  },
  methods: {
    updateTotalHT() {
      const quantite = parseFloat(this.materialObject.quantite) || 0;
      const PUHT = parseFloat(this.materialObject.PUHT) || 0;
      this.materialObject.totalHT = quantite * PUHT;
    },
    validateSousTotal(newTotal, indexLOT, indexSubLOT) {
      let validationSuccess = true;
      // Validating a subLOT total
      let currentTotalSum = 0;
      for (let idx = 0; idx < this.devisCalcul.resultatSelected.length; idx++) {
        const lot = this.devisCalcul.resultatSelected[idx];
        let newSubLotSum = 0;
        for (let idxSub = 0; idxSub < lot.items.length; idxSub++) {
          const subLot = lot.items[idxSub];
          // Use newTotal for the current subLot being edited, use existing for others
          if (idxSub === indexSubLOT) {
            newSubLotSum =
              parseFloat(newSubLotSum.toFixed(2)) + parseFloat(newTotal);
          } else {
            newSubLotSum =
              parseFloat(newSubLotSum.toFixed(2)) + parseFloat(subLot.total);
          }
        }
        currentTotalSum =
          parseFloat(currentTotalSum.toFixed(2)) +
          parseFloat(newSubLotSum.toFixed(2));
      }

      // Check if the new sum of subLots exceeds the parent lot total
      if (
        parseFloat(currentTotalSum.toFixed(2)) >
        parseFloat(this.devisCalcul.tvaResultat.TotalHT)
      ) {
        validationSuccess = false;
      }

      return validationSuccess;
    },
    validateTotalLOT(newTotal, indexLOT) {
      let validationSuccess = true;

      // Validating a LOT total
      let currentTotalSum = 0;
      for (let idx = 0; idx < this.devisCalcul.resultatSelected.length; idx++) {
        const lot = this.devisCalcul.resultatSelected[idx];
        // Use newTotal for the current lot being edited, use existing for others
        if (idx === indexLOT) {
          currentTotalSum =
            parseFloat(currentTotalSum.toFixed(2)) + parseFloat(newTotal);
        } else {
          currentTotalSum =
            parseFloat(currentTotalSum.toFixed(2)) + parseFloat(lot.total);
        }
      }

      // Check if the new sum of LOTs exceeds TotalHT
      if (
        parseFloat(currentTotalSum.toFixed(2)) >
        parseFloat(this.devisCalcul.tvaResultat.TotalHT)
      ) {
        validationSuccess = false;
      }

      return validationSuccess;
    },
    editSubLOTTotal(objectToModify, indexLOT, indexSubLOT) {
      let formRef = `editSubLOTTotal-${indexLOT}-${indexSubLOT}`;
      if (this.$refs[formRef][0].validate()) {
        // objectToModify.total = this.newTotal;
        // calculate the sum of subLOT Total for current LOT changed
        let sumOfSubLOT = 0;
        for (
          let idx = 0;
          idx < this.devisCalcul.resultatSelected[indexLOT].items.length;
          idx++
        ) {
          const subLot = this.devisCalcul.resultatSelected[indexLOT].items[idx];
          if (idx === indexSubLOT) {
            sumOfSubLOT = parseFloat(sumOfSubLOT.toFixed(2)) + parseFloat(this.newTotal);
          } else {
            sumOfSubLOT = parseFloat(sumOfSubLOT.toFixed(2)) + parseFloat(subLot.total);
          }
        }

        this.$swal({
          title: "Êtes-vous sûr?",
          text: "Vous ne pourrez pas revenir en arrière!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#007991",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Je Confirme",
        }).then((result) => {
          if (result.isConfirmed) {
            // this.devisCalcul.resultatSelected[indexLOT].total = parseFloat(
            //   sumOfSubLOT.toFixed(2)
            // );
            let newCopy = JSON.parse(
              JSON.stringify(this.devisCalcul.resultatSelected[indexLOT])
            );
            newCopy.total = parseFloat(sumOfSubLOT.toFixed(2));
            newCopy.items[indexSubLOT].total = this.newTotal;
            this.$emit("updateTotal", {
              lot: newCopy,
            });
            // change LOT Total with new total
            this.editingKey = null; // Exit edit mode
          }
        })
      }
    },
    editLOTTotal(objectToModify, indexLOT) {
      let formRef = `editLOTTotal-${indexLOT}`;
      if (this.$refs[formRef][0].validate()) {
        this.$swal({
          title: "Êtes-vous sûr?",
          text: "Vous ne pourrez pas revenir en arrière!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#007991",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Je Confirme",
        }).then((result) => {
          if (result.isConfirmed) {
            // objectToModify.total = this.newTotal;
            let newCopy = JSON.parse(JSON.stringify(objectToModify));
            newCopy.total = this.newTotal;
            newCopy.items[0].total = this.newTotal;
            this.$emit("updateTotal", {
              lot: newCopy,
            });
            this.editingKey = null; // Exit edit mode
          }
        })
      }
    },
    toggleEditSousTotalLOT(objectToModify, key) {
      if (this.editingKey != null) {
        this.editingKey = null;
        this.originalTotal = null;
        this.newTotal = null;
      } else {
        // Store the original value before editing
        this.originalTotal = objectToModify.total;
        // Set the newTotal value to old one before editing
        this.newTotal = objectToModify.total;
        // Set the editing key to the current key
        this.editingKey = key;
      }
    },
    cancelEditSousTotalLOT(objectToModify, key) {
      // Restore the original value
      objectToModify.total = this.originalTotal;
      // Reset the editing key
      this.editingKey = null;
    },
    to_fixed2LocaleString(num) {
      return num != null && num != undefined
        ? Number(parseFloat(num).toFixed(2))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString() + ""
        : "--";
    },
    hasSublotsWithSubtitles(lot) {
      return lot.items.some((subLot) => subLot.subtitle !== "");
    },
    addNewMaterial() {
      if (this.$refs.addNewMaterial.validate() && this.changedLOTObject) {
        this.$swal({
          title: "Êtes-vous sûr?",
          text: "Vous ne pourrez pas revenir en arrière!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#007991",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Je Confirme",
        }).then((result) => {
          if (result.isConfirmed) {
            let newObj = {
              ...this.materialObject,
              aux: true,
              new: true,
            }
            if (
              this.changedSubLOTIndex !== "" &&
              this.changedSubLOTIndex !== null &&
              this.changedSubLOTIndex !== undefined &&
              this.changedSubLOTIndex >= 0
            ) {
              this.changedLOTObject.items[this.changedSubLOTIndex].items.push(newObj);
            } else {
              // change only the first SubLOT because there is no others SubLOTs
              this.changedLOTObject.items[0].items.push(newObj);
            }
            this.$emit("ajoutMaterial", {
              lot: this.changedLOTObject,
            });
            this.closeModalAddMaterial()
          }
        })
      }
    },
    openModalAddMaterial(obj, indexSubLOT) {
      this.changedLOTObject = JSON.parse(JSON.stringify(obj));
      if (indexSubLOT >= 0) {
        this.changedSubLOTIndex = indexSubLOT;
      }
      this.modalAddMaterial = true;
    },
    closeModalAddMaterial() {
      this.modalAddMaterial = false;
      this.changedLOTObject = null;
      this.changedSubLOTIndex = null;
      this.materialObject = {
        description: "",
        unite: "U",
        quantite: 1,
        PUHT: 0,
        totalHT: 0,
      };
    },
    // delete LOT
    deleteLOT(devisLOTObject) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit('deleteLotObject', {
            title: devisLOTObject.title
          });
        }
      })
    },
    // delete material
    deleteMaterial(indexLOT, indexSubLOT, indexMaterial) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) { 
          // this.devisCalcul.resultatSelected[indexLOT].items[indexSubLOT].items.splice(indexMaterial, 1);
          let lotChanged = JSON.parse(JSON.stringify(this.devisCalcul.resultatSelected[indexLOT]));
          
          let item = lotChanged.items[indexSubLOT].items[indexMaterial]
          if("tmptotalHT" in item){
            lotChanged.items[indexSubLOT]['total'] -= item['tmptotalHT']
            lotChanged['total'] -= item['tmptotalHT']
          }
          lotChanged.items[indexSubLOT].items.splice(indexMaterial, 1);
          this.$emit("deleteLOTMaterial", {
            lot: lotChanged,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  table {
    width: 100%;
    thead {
      tr {
        border-left: 1px solid rgba(238, 238, 238, 255);
        border-right: 1px solid rgba(238, 238, 238, 255);
      }
      td,
      th {
        padding: 8px;
        border-left: 1px solid rgba(238, 238, 238, 255);
        border-right: 1px solid rgba(238, 238, 238, 255);
        vertical-align: middle;
      }
    }
    tbody {
      td {
        padding: 4px 4px;
        border-left: 1px solid rgba(238, 238, 238, 255);
        border-right: 1px solid rgba(238, 238, 238, 255);
        vertical-align: top;
      }
      .title {
        font-family: "Poppins-Bold";
        font-size: 18px;
        font-weight: 700;
      }
      .subtitle {
        font-family: "Poppins-Bold";
        font-size: 16px;
        font-weight: 700;
      }
      .material {
        font-size: 12px;
        font-weight: 400;
      }
      .line-number {
        font-size: 14px;
        font-weight: 400;
      }
      .line-number-bold {
        font-size: 16px;
        font-weight: 700;
      }
      .line-value {
        font-size: 14px;
        font-weight: 400;
      }
    }
    tr:last-child {
      border-bottom: 1px solid rgba(238, 238, 238, 255);
    }
  }
}
</style>

<style lang="scss" scoped>
.tooltip-edit-value {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  bottom: 125%;
  right: 0px;
  margin-left: -50%;
  z-index: 10;
  min-width: 200px;
  background-color: #ffffff;
  color: #141414;
  border-radius: 4px;
  padding: 4px 4px;
  box-shadow: 0px 4px 8px 0px #a9a9a9;
  transition: opacity 0.3s;
  &.active {
    visibility: visible;
    opacity: 1;
  }
}
// .tooltip-edit-value::after {
//   content: "";
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   margin-left: -5px;
//   border-width: 5px;
//   border-style: solid;
//   border-color: #ffffff transparent transparent transparent;
// }
</style>
