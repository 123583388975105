<template>
    <v-card class="m-4" elevation="10">
       <v-toolbar
        :style="{background:$styles.background}"
        class="text-white"
        dark
        >
                <v-card-title class="d-flex justify-content-center align-items-center text-white">Ajouter une équipe</v-card-title>
        </v-toolbar>
        <v-row v-if="loadingDialog || error">
      <loadingComponent
        @closeDialog="close($event)"
        :loadingProps="loadingDialog"
        :messageDialogeProps="messageDialoge"
        :dialogProps="dialog"
        :errorProps="error"
      />
    </v-row>
        <v-form ref="form" lazy-validation class="p-4">
            <v-row class="mt-4">
                <v-col cols="12" md="12" lg="3" xl="3" >
                   <v-text-field
                        dense
                        outlined
                        :rules="[$rules.required('un nom d\'équipe'),$rules.isSanitize]"
                        v-model="nomEquipe"
                        label="Nom d'équipe"
                    ></v-text-field>
                </v-col>
                
                <v-col v-if="['admin','superadmin'].includes($store.getters.getUserRole)" cols="12" md="12" lg="3" xl="3" >
                    <v-autocomplete
                        dense
                        outlined
                        :items="listeADV"
                        item-text="userName"
                        item-value="id"
                        v-model="selectADV"
                        label="ADV"
                        :rules="[$rules.required('un ADV est obligatoire'),$rules.isSanitize]"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="3" xl="3" >
                    <v-autocomplete
                        dense
                        outlined
                        :items="filteredListChef"
                        item-text="userName"
                        item-value="id"
                        v-model="chefEquipe"
                        label="Chef d'équipe"
                         :rules="[$rules.required('un chef d\'équipe'),$rules.isSanitize]"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.userName}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="3" xl="3" >
                    <v-autocomplete
                        dense
                        outlined
                        :items="listeChefIngenieur"
                        item-text="userName"
                        item-value="id"
                        v-model="chefIngenieur"
                        label="Chef Ingenieur"
                        :rules="[$rules.isSanitize]"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.userName}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="4" xl="4">
                    <v-autocomplete 
                        v-model="selectedProspecteurs" 
                        :items="filteredProspecteurs" 
                        :loading="isLoading" 
                        hide-no-data 
                        hide-selected 
                        item-text="userName" 
                        item-value="id" 
                        label="Prospecteur" 
                        placeholder="Trouver des prospecteurs" 
                        prepend-inner-icon="mdi-account-search-outline"
                        chips
                        dense
                        outlined
                        deletable-chips
                        multiple
                        :rules="[$rules.required('un Prospecteur est obligatoire'),$rules.isSanitize]"
                        ><!--:rules="[rules.requiredSelect]" mis en commentaire car prospecteur n'a pas tout le temps de valeur-->
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.userName}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="4" xl="4">
                    <v-autocomplete 
                        v-model="selectedTechnicien" 
                        :items="Techniciens" 
                        :loading="isLoading" 
                        hide-no-data 
                        item-text="prenom" 
                        item-value="id" 
                        label="Technicien" 
                        placeholder="Trouver des techniciens" 
                        prepend-inner-icon="mdi-account-search-outline"
                        chips
                        dense
                        outlined
                        deletable-chips
                        multiple
                        :rules="[$rules.required('un technicien est obligatoire'),$rules.isSanitize]"
                        ><!--:rules="[rules.requiredSelect]" mis en commentaire car prospecteur n'a pas tout le temps de valeur-->
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.prenom+" "+data.item.nom}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="4" xl="4">
                    <v-autocomplete 
                        v-model="selectedIngenieur" 
                        :items="Ingenieurs" 
                        :loading="isLoading" 
                        hide-no-data 
                        item-text="prenom" 
                        item-value="id" 
                        label="Ingenieur" 
                        placeholder="Trouver un ingenieur" 
                        prepend-inner-icon="mdi-account-search-outline"
                        chips
                        dense
                        outlined
                        deletable-chips
                        multiple
                        :rules="[$rules.required('un ingenieur est obligatoire'),$rules.isSanitize]"
                        ><!--:rules="[rules.requiredSelect]" mis en commentaire car prospecteur n'a pas tout le temps de valeur-->
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.prenom+" "+data.item.nom}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div class='d-flex justify-content-end'>
                        <v-btn @click="$emit('ajouterEquipeMethod', false)" color="error" text class='mx-2'>Annuler</v-btn>
                        <v-btn @click="creerEquipe" :style="{background:$styles.background}" class="text-white"> <span class="Poppins-Bold">créer l'équipe</span></v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>
<script>
    import axios from "@/plugins/axios";
    import Multiselect from 'vue-multiselect'
    import loadingComponent from '../../components/Loading'
    export default {
        name: "ajouterEquipe",
        components: {
            loadingComponent,
            Multiselect:Multiselect
        },
        data() {
            return {
                //dialoge delete
                idItemDeleted: null,
                dialogDelete:false,
                search: '',
                error: false,
                dialog: false,
                loadingDialog: false,
                messageDialoge:"",
                nomEquipe: "",
                listeADV: [],
                chefEquipe:"",
                chefIngenieur:"",
                listeChef:[],
                listeEquipe: [],
                listeChefIngenieur:[],
                selectADV: "",
                Prospecteurs: [],
                Techniciens:[],
                Ingenieurs:[],
                selectedTechnicien:[],
                selectedProspecteurs: [],
                selectedIngenieur:[],
                isLoading: false,
            }
        },
        async created() {
            if(this.$store.getters.getUserRole == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                await localStorage.setItem('typeUser', this.$store.getters.getUserRole)
                this.getAllAdv();
                this.getAllChef();
                this.getAllChefIngenieur();
                this.getAllProspecteur();
                this.getAllTechnicien();
                this.getAllIngenieur();
            }
        },
        computed: {
            filteredList() {
                return this.listeEquipe.filter(post => {
                    return post.nomEquipe.toLowerCase().includes(this.search.toLowerCase())
                })
            },
            filteredListChef() {
                // Filter out the selected chefEquipe from the Prospecteurs list
                return this.listeChef.filter(chef => !this.selectedProspecteurs.includes(chef.id));
            },
            filteredProspecteurs() {
                // Filter out the selected chefEquipe from the Prospecteurs list
                return this.Prospecteurs.filter(prospecteur => prospecteur.id !== this.chefEquipe);
            },
        },
        methods: {
            close(event){
                this.error = event;
                this.dialog = event;
            },
            emit(){
                this.$emit('ajouterEquipeMethod', true)
            },
            reinitialiser(){
                this.nomEquipe= ""
                this.listeADV= []
                this.chefEquipe=""
                this.chefIngenieur=""
                this.listeChef=[]
                this.selectADV= null
                this.Prospecteurs= []
                this.Techniciens = []
                this.Ingenieurs = []
                this.selectedTechnicien = [],
                this.selectedIngenieur = [],
                this.selectedProspecteurs= []
                this.isLoading= false
                this.getAllAdv()
                this.getAllChef();
                this.getAllChefIngenieur();
                
                this.getAllProspecteur()
                this.getAllTechnicien()
                this.getAllIngenieur()
            },
            openSupprimerEquipe(id){
                this.idItemDeleted = id
                this.dialogDelete = true
            },
            async supprimerEquipe(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                formData.append('idEquipe', this.idItemDeleted)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'supprimerEquipe/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    this.dialogDelete = false
                    if(res.data.error == false){
                        this.dialog=true
                        this.loadingDialog=false
                        this.error = false
                        this.messageDialoge= res.data.message
                    }else if(res.data.error == true){
                        this.dialog=true
                        this.loadingDialog=false
                        this.error = true
                        this.messageDialoge= res.data.message
                    }else if(res.data.error == "permission"){
                        this.loadingDialog=false
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                    this.getAllAdv()
                    this.getAllChef()
                    
                    this.getAllChefIngenieur();
                    this.getAllProspecteur()
                    this.getAllTechnicien()
                    this.getAllIngenieur()
                }).catch((err)=>{
                    this.dialogDelete = false
                    this.reinitialiser()
                    this.getAllAdv()
                    this.getAllChef()
                    
                this.getAllChefIngenieur();
                    this.getAllProspecteur()
                    this.getAllTechnicien()
                    this.getAllIngenieur()
                })
            },
            async creerEquipe(){
                if (this.$refs.form.validate()) {
                    this.dialog=true
                    this.loadingDialog=true
                    var formData = new FormData()
                    formData.append('token', this.$store.getters.getUserToken)
                    formData.append('nomEquipe', this.nomEquipe)
                    if(['admin','superadmin'].includes(this.$store.getters.getUserRole)){
                        formData.append('ADV', this.selectADV)
                    }
                    formData.append('chefEquipe', this.chefEquipe)
                    formData.append('chefIngenieur', this.chefIngenieur)
                    formData.append('prespecteurs', this.selectedProspecteurs)
                    formData.append('techniciens', this.selectedTechnicien)
                    formData.append('ingenieurs', this.selectedIngenieur)
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'createEquipe/',
                        method: 'POST',
                        data: formData,
                    }).then((res)=>{
                        if(res.data.error == false){
                            this.error = false
                            this.messageDialoge= "Création de l'équipe réussie"
                            this.reinitialiser()
                        }else if(res.data.error == true){
                            this.error = true
                            this.messageDialoge= res.data.message
                        }else if(res.data.error == "permission"){
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                        this.loadingDialog=false
                        this.getAllAdv()
                        this.getAllChef()
                        this.getAllChefIngenieur();
                        this.getAllProspecteur()
                        this.getAllTechnicien()
                        this.getAllIngenieur()
                    }).catch((err)=>{
                        console.log(err)
                        this.loadingDialog=false
                        this.reinitialiser()
                        this.getAllAdv()
                        this.getAllChef()
                        this.getAllChefIngenieur();
                        this.getAllProspecteur()
                        this.getAllTechnicien()
                        this.getAllIngenieur()
                    })
                    this.emit()
                }
            },
            async getAllTechnicien(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllTechnicien/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    console.log(res.data.result)
                    if(res.data.error == false){
                        this.Techniciens = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
             async getAllIngenieur(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllIngenieur/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    console.log(res.data.result)
                    if(res.data.error == false){
                        this.Ingenieurs = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllProspecteur(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllProspecteur/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.Prospecteurs = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllAdv(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllADV/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    console.log(res.data.result)
                    if(res.data.error == false){
                        this.listeADV = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllChef(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllChefEquipe/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.listeChef = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllChefIngenieur(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllChefIngenieur/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.listeChefIngenieur = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
        }
    }
</script>