<template>
  <div>
    <!-- Modal Add Image and Download PDF -->
    <div
      class="modal fade"
      id="modalAddImageAndDownloadPdf"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddImageAndDownloadPdfLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAddImageAndDownloadPdfLabel">
              Télécharger une Présentation PDF
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <v-file-input
            v-model="addressImage"
            label="Addresse"
            placeholder="Addresse"
            prepend-icon="mdi-camera"
            accept="image/png, image/jpeg"
            ></v-file-input>
            <v-file-input
            v-model="cellpinageImage"
            label="Cellpinage"
            placeholder="Cellpinage"
            prepend-icon="mdi-camera"
            accept="image/png, image/jpeg"
            ></v-file-input>

            <!-- buttons -->
            <v-btn class="button-download-pdf" :color="$colors[0]" @click="generatePdf()" :loading="loadingGeneratingPres">
              <v-icon class="white--text">mdi-download-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- ******************************* -->
    <!-- button toggle modal image input -->
    <!-- ******************************* -->
    <v-btn
      class="ml-4"
      data-toggle="modal"
      data-target="#modalAddImageAndDownloadPdf"
      :color="$colors[0]"
    >
      <v-icon class="white--text">mdi-download-outline</v-icon>
    </v-btn>

    <!-- pdf content to render inside pdf (chart) -->
    <!-- <div id="pdf-content">
      <div class="chart-wrapper">
        <apexchart 
        type="bar" 
        height="600px" 
        :options="RSIChartOptions" 
        :series="RSIChartSeries">
        </apexchart>
      </div>
      <div class="chart-wrapper">
        <apexchart 
        type="line" 
        height="500px" 
        :options="factureEnergieChartOptions" 
        :series="factureEnergieChartSeries">
        </apexchart>
      </div>
      <div class="chart-wrapper">
        <apexchart 
        type="donut" 
        width="1000px" 
        height="500px" 
        :options="repartitionEnergieProduiteChartOptions" 
        :series="repartitionEnergieProduiteChartSeries">
        </apexchart>
      </div>
      <div class="chart-wrapper">
        <apexchart 
        type="donut" 
        width="1000px" 
        height="500px" 
        :options="productionAnnuelleChartOptions" 
        :series="productionAnnuelleChartSeries">
        </apexchart>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import PDFSolarCollectifGenerator from './PDFSolarCollectifGenerator.js';
export default {
  props: {
    pdfData: Object,
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loadingGeneratingPres: false,
      showBudget: false,
      // modal image
      addressImage: null,
      cellpinageImage: null,

      pdfDataToSend: null,

      /*
      RSIChartSeries: [{
        name: 'Economie cumulée(€)',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],
      RSIChartOptions: {
        chart: {
          id: 'RSIChart',
          type: 'bar',
          height: 600,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true
            }
          },   
          yaxis: {
            lines: {
              show: true
            }
          }, 
        },
        colors: ['#548235'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          title: {
            text: 'Années',
          },
          type: "numeric",
          tickAmount: "dataPoints", // to all x axis numeric numbers
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          },
        },
        yaxis: {
          title: {
            text: 'Economie cumulée(€)',
          },
          labels: {
            formatter: function (value) {
              return Number(parseFloat(value).toFixed(2)).toLocaleString("fr-FR") + " €";
            }
          }
        },
        fill: {
          opacity: 1
        },
      },
      factureEnergieChartSeries: [
        {
          name: 'Facture Energie (€) TTC avec PC',
          data: [0,0,0,0,0,0,0,0]
        },
        {
          name: 'Facture Energie (€) TTC sans PC',
          data: [0,0,0,0,0,0,0,0]
        }
      ],
      factureEnergieChartOptions:{
        chart: {
          id: 'factureEnergieChart',
          group: 'social',
          type: 'line',
          height: 500,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true
            }
          },   
          yaxis: {
            lines: {
              show: false
            }
          }, 
        },
        colors: ['#5a9bd2','#eb7d2d'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 5,
        },
        stroke: {
          show: true,
          width: 3,
        },
        xaxis: {
          type: "numeric",
          tickAmount: "dataPoints", // to all x axis numeric numbers
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          },
        },
        yaxis: {
          title: {
            text: 'MONTANT (€)',
          },
          labels: {
            formatter: function (value) {
              return Number(parseFloat(value).toFixed(2)).toLocaleString("fr-FR") + " €";
            }
          }
        },
        fill: {
          opacity: 1
        },
      },
      productionAnnuelleChartSeries: [44, 55, 41],
      productionAnnuelleChartOptions: {
        chart: {
          id: 'productionAnnuelleChart',
          type: 'donut',
          background: "#f5f5f5",
        },
        labels: ["Autoconsommation individuelle (ACI)", "Autoconsommation collective (ACC)", "Surplus de production"],
        title: {
          text: "Estimation de production (kWh par an)",
          align: 'center',
          margin: 20,
          style: {
            fontSize:  '20px',
            fontWeight:  'bold',
            color:  '#000'
          },
        },
        legend: {
          show: true,
          position: 'top',
        },
        stroke: {
          width: 0
        },
        colors: ["#416ec3","#eb7d2d","#a5a5a5","#fabe00","#5a9bd2","#6eaa46","#234178","#6e2da0"],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '18px',
            colors: ['#111']
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 6,
            borderWidth: 0
          }
        },
      },
      repartitionEnergieProduiteChartSeries: [44, 55, 41],
      repartitionEnergieProduiteChartOptions: {
        chart: {
          id: 'repartitionEnergieProduiteChart',
          type: 'donut',
          background: "#f5f5f5",
        },
        labels: ["Autoconsommation individuelle (ACI)", "Autoconsommation collective (ACC)", "Surplus de production"],
        title: {
          text: "Répartition des consommations de l'énergie PV produite (en kWh par an)",
          align: 'center',
          margin: 20,
          style: {
            fontSize:  '20px',
            fontWeight:  'bold',
            color:  '#000'
          },
        },
        legend: {
          show: true,
          position: 'right',
          offsetY:  200,
        },
        stroke: {
          width: 0
        },
        colors: ["#416ec3","#eb7d2d","#a5a5a5","#fabe00","#5a9bd2","#6eaa46","#234178","#6e2da0"],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '18px',
            colors: ['#111']
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 6,
            borderWidth: 0
          }
        },
      },
      */
    };
  },
  watch: {
    pdfData: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.fillPropsData();
      },
    },
  },
  methods: {
    fillPropsData() {
      this.pdfDataToSend = this.pdfData;
      // this.RSIChartSeries[0].data = [-1500000, -1200000, -1000000, -800000, -600000, -400000, -100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1100000, 1200000, 1300000, 1400000];
      // this.factureEnergieChartSeries[0].data = [1900000, 2000000, 2100000, 2200000, 2300000, 2400000, 2500000, 2600000];
      // this.factureEnergieChartSeries[1].data = [2000000,2100000,2200000,2300000,2400000,2500000,2600000,2700000];
    },
    // format pdf numbers
    to_fixed2LocaleString(num) {
      return num != null && num >= 0
        ? Number(parseFloat(num).toFixed(2))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString()
        : "--";
    },
    formatNumberOrText(num, tofFixedNumber) {
      if(!isNaN(num) && num !== null && num !== undefined && num !== ""){
          // num is number
          return Number(parseFloat(num).toFixed(tofFixedNumber)).toLocaleString("fr-FR").replace(/\u202F/g, ' ').toString() + "";
      }else{
          // num is text
          if(num !== null && num !== undefined && num !== ""){
              return num
          }
          return ""
      }
    },

    // pdf generation
    async generatePdf() {
      try {
        this.loadingGeneratingPres = true;
        // Initialize PDFGenerator
        const PDFGenerator = new PDFSolarCollectifGenerator({
          addressImage: this.addressImage,
          cellpinageImage: this.cellpinageImage,
          pdfData: this.pdfDataToSend,
        });
        await PDFGenerator.generatePresentation();
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingGeneratingPres = false;
      }
    },
  },
};
</script>

<!-- modal style -->
<style scoped>
.modal-header {
  background: rgb(0, 121, 145);
  color: #fff !important;
}
.button-download-pdf {
  width: 100%;
  height: 44px;
  margin-top: 20px;
}
</style>

<!-- pdf charts style -->
<style scoped>
#pdf-content {
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.chart-wrapper {
  width: 1200px;
  height: 600px;
  background: #fff;
}
</style>
