<template>
    <v-card  elevation="10">
        <v-toolbar
        :style="{background:$styles.background}"
        dark
        >
                <v-card-title class="d-flex Poppins-Bold justify-content-center align-items-center text-white">Modifier l'équipe</v-card-title>
        </v-toolbar>
        <v-row class="my-4">
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog == true">
                <dialogeModal
                    :error="error"
                    :dialog="dialog"
                    :loading="loadingDialog"
                    @closeDialog="dialog = false"
                    titre="Ajouter une équipe"
                    :messageDialogeSucces="this.messageDialogeSucces"
                    :messageDialogeError="this.messageDialogeError" />
            </v-col>
        </v-row>
        <div class="d-flex flex-column"> 
        <v-form ref="form" lazy-validation class="p-4">
            <v-row cols="12">
                <v-col cols="12" md="12" lg="3" xl="3" >
                   <v-text-field
                        dense
                        outlined
                        :rules="[$rules.required('un nom d\'équipe'),$rules.isSanitize]"
                        v-model="nomEquipe"
                        label="Nom d'équipe"
                    ></v-text-field>
                </v-col>
                <v-col v-if="['admin','superadmin'].includes($store.getters.getUserRole)" cols="12" md="12" lg="3" xl="3" >
                    <v-autocomplete
                        dense
                        outlined
                        :items="listeADV"
                        item-text="userName"
                        item-value="id"
                        v-model="ADV"
                        label="ADV"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="3" xl="3" >
                    <v-autocomplete
                        dense
                        outlined
                        :items="filteredListChef"
                        item-text="userName"
                        item-value="id"
                        v-model="chefEquipe"
                        label="Chef d'équipe"
                         :rules="[$rules.required('un chef d\'équipe'),$rules.isSanitize]"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.userName}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="3" xl="3" >
                    <v-autocomplete
                        dense
                        outlined
                        :items="listeChefIngenieur"
                        item-text="userName"
                        item-value="id"
                        v-model="chefIngenieur"
                        label="Chef Ingenieur"
                         :rules="[$rules.isSanitize]"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.userName}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="4" xl="4">
                    <v-autocomplete 
                        v-model="selectedProspecteurs" 
                        :items="filteredProspecteurs" 
                        :loading="isLoading" 
                        hide-no-data 
                        hide-selected 
                        item-text="userName" 
                        item-value="id" 
                        label="Prospecteur" 
                        placeholder="Trouver des prospecteurs" 
                        prepend-inner-icon="mdi-account-search-outline"
                        chips
                        dense
                        outlined
                        deletable-chips
                        multiple
                         :rules="[$rules.required('Prospecteur'),$rules.isSanitize]"
                        ><!--:rules="[rules.requiredSelect]" mis en commentaire car prospecteur n'a pas tout le temps de valeur-->
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.userName}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="4" xl="4">
                    <v-autocomplete 
                        v-model="selectedTechnicien" 
                        :items="Techniciens" 
                        :loading="isLoading" 
                        hide-no-data 
                        item-text="prenom" 
                        item-value="id" 
                        label="Technicien" 
                        placeholder="Trouver des techniciens" 
                        prepend-inner-icon="mdi-account-search-outline"
                        chips
                        dense
                        outlined
                        deletable-chips
                        multiple
                         :rules="[$rules.required('un technicien'),$rules.isSanitize]"
                        ><!--:rules="[rules.requiredSelect]" mis en commentaire car prospecteur n'a pas tout le temps de valeur-->
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.prenom+" "+data.item.nom}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="4" xl="4">
                    <v-autocomplete 
                        v-model="selectedIngenieur" 
                        :items="Ingenieurs" 
                        :loading="isLoading" 
                        hide-no-data 
                        item-text="prenom" 
                        item-value="id" 
                        label="Ingenieur" 
                        placeholder="Trouver un ingenieur" 
                        prepend-inner-icon="mdi-account-search-outline"
                        chips
                        dense
                        outlined
                        deletable-chips
                        multiple
                         :rules="[$rules.required('un ingenieur'),$rules.isSanitize]"
                        ><!--:rules="[rules.requiredSelect]" mis en commentaire car prospecteur n'a pas tout le temps de valeur-->
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.prenom+" "+data.item.nom}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.type}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <div class="d-flex flex-column">
                <div class="d-flex justify-content-end ma-2">
                    <v-btn @click="$emit('modifierEquipe', false)" color="error" text>Annuler</v-btn>
                    <v-btn @click="editEquipe" :style="{background:$styles.background}" class="ml-2 text-white"><span class="Poppins-Bold">Modifier</span></v-btn>
                </div>
            </div>
        </v-form>
        </div>
    </v-card>
</template>
<script>
    import axios from "@/plugins/axios";
    import Multiselect from 'vue-multiselect'
    import dialogeModal from '../../components/ui/dialogeModal'
    export default {
        name: "modifierEquipe",
         props: {
            equipe: null,
        },
        components: {
            dialogeModal:dialogeModal,
            Multiselect:Multiselect
        },
        data() {
            return {
                //dialoge delete
                idItemDeleted: null,
                dialogDelete:false,
                search: '',
                error: false,
                dialog: false,
                loadingDialog: false,
                isLoading: false,
                messageDialogeSucces: "",
                messageDialogeError: "",

                listeADV: [],
                listeChef: [],
                listeChefIngenieur:[],
                Prospecteurs: [],
                Techniciens:[],
                Ingenieurs: [],
                
                nomEquipe: "",
                ADV: "",
                chefEquipe: "",
                chefIngenieur:"",
                selectedTechnicien:[],
                selectedProspecteurs: [],
                selectedIngenieur: [],
                
            }
        },
        async mounted() {
            await this.init();
        },
        async created() {
            this.getAllAdv();
            this.getAllChef();
            this.getAllProspecteur();
            this.getAllTechnicien();
            this.getAllIngenieur();
            this.getAllChefIngenieur();
        },
        computed: {
            filteredListChef() {
                // Filter out the selected chefEquipe from the Prospecteurs list
                return this.listeChef.filter(chef => !this.selectedProspecteurs?.includes(chef.id));
            },
            filteredProspecteurs() {
                // Filter out the selected chefEquipe from the Prospecteurs list
                return this.Prospecteurs.filter(prospecteur => prospecteur.id !== this.chefEquipe);
            },
        },
        methods: {
            emit(){
                this.$emit('modifierEquipe', false)
            },
            init() {
                if (this.equipe) {
                    this.nomEquipe = this.equipe.nomEquipe;
                    this.ADV = this.equipe.ADV?.id;
                    this.chefEquipe = this.equipe.chefEquipe?.id;
                    this.chefIngenieur = this.equipe.chefIngenieur?.id;
                    this.selectedIngenieur = this.equipe.Ingenieurs.map(el=> el.id);
                    this.selectedProspecteurs = this.equipe.prospecteur.map(el=> el.id);
                    this.selectedTechnicien = this.equipe.Techniciens.map(el=> el.id);
                } 
            },
            reinitialiser() {
                this.nomEquipe= ""
                this.listeADV= []
                this.chefEquipe = ""
                this.chefIngenieur=""
                this.listeChef = []
                this.listeChefIngenieur = []
                this.ADV= null
                this.Prospecteurs= []
                this.selectedProspecteurs= []
                this.selectedIngenieur = [];
                this.selectedTechnicien = [];
                this.isLoading= false
                this.getAllAdv()
                this.getAllChef()
                this.getAllProspecteur()
                this.getAllTechnicien()
                this.getAllIngenieur()
                this.getAllChefIngenieur();
            },
            async editEquipe(){
                if (this.$refs.form.validate()) {
                    this.dialog=true
                    this.loadingDialog=true
                    var formData = new FormData()
                    formData.append('token', this.$store.getters.getUserToken)
                    formData.append('idEquipe', this.equipe.id)
                    formData.append('nomEquipe', this.nomEquipe)
                    if(['admin','superadmin'].includes(this.$store.getters.getUserRole)){
                        formData.append('ADV', this.ADV)
                    }
                    formData.append('chefEquipe', this.chefEquipe)
                    formData.append('chefIngenieur', this.chefIngenieur)
                    formData.append('prespecteurs', this.selectedProspecteurs)
                    formData.append('techniciens', this.selectedTechnicien)
                    formData.append('ingenieurs', this.selectedIngenieur)
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'modifierEquipeAdmin/',
                        method: 'POST',
                        data: formData,
                    }).then((res)=>{
                        if(res.data.error == false){
                            this.loadingDialog=false
                            this.error = false
                            this.messageDialogeSucces= res.data.message
                            this.messageDialogeError= ""
                            this.reinitialiser()
                            this.emit()
                        }else if(res.data.error == true){
                            this.loadingDialog=false
                            this.error = true
                            this.messageDialogeSucces= ""
                            this.messageDialogeError= res.data.message
                        }else if(res.data.error == "permission"){
                            
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                        this.getAllAdv()
                        this.getAllChef()
                        this.getAllChefIngenieur();
                        this.getAllProspecteur()
                        this.getAllTechnicien()
                        this.getAllIngenieur()
                    }).catch((err)=>{
                        console.log(err)
                        this.reinitialiser()
                        this.getAllAdv()
                        this.getAllChef()
                        this.getAllChefIngenieur();
                        this.getAllProspecteur()
                        this.getAllTechnicien()
                        this.getAllIngenieur()
                        this.emit()
                    })
                }
            },
            async getAllAdv(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllADV/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.listeADV = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllChef(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllChefEquipe/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.listeChef = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllTechnicien(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllTechnicien/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    console.log(res.data.result)
                    if(res.data.error == false){
                        this.Techniciens = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
             async getAllIngenieur(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllIngenieur/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    console.log(res.data.result)
                    if(res.data.error == false){
                        this.Ingenieurs = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllProspecteur(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllProspecteur/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.Prospecteurs = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllChefIngenieur(){
                var formData = new FormData()
                formData.append('token', this.$store.getters.getUserToken)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllChefIngenieur/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.listeChefIngenieur = res.data.result
                    }else if(res.data.error == "permission"){
                        
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
        }
    }
</script>