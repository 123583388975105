<template>
  <div class="p-4">
    <div class="shadow-sm">
      <breadcrumb :listNavs="listNavs" />
    </div>
    <v-card class="p-4">
      <div style="width: 100%;overflow-x: auto;">
        <datatable
          id="table-validation-cotation"
          :columns="columns"
          :sortKey="sortKey"
          :sortOrders="sortOrders"
          @sort="sortBy"
          @search="rechercheData"
        >
          <template v-slot:header>
            <div style="width: 200px" class="float-end container-div mr-2">
              <select
                v-model="filterStatus"
                class="form-control"
                id="sel1"
                @change="filterByStatus"
              >
                <option value="tout">Toutes les demandes</option>
                <option value="1">acceptée</option>
                <option value="0">en attente</option>
                <option value="2">refusée</option>
              </select>
            </div>
          </template>
  
          <template v-slot:body>
            <tbody class="text-center" v-if="projects.length > 0 && loadingTable == false">
              <tr v-for="(project, index) in projects" :key="project.id">
                <td>{{ project.dateAjout }}</td>
                <td>{{ project.compte.nom + " " + project.compte.prenom }}</td>
                <td>
                  <v-tooltip v-if="project.societe.raison.length >= 20" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <small class="td-table" v-bind="attrs" v-on="on">
                        <router-link :to="`/detail-societe/${project.societe.id}`">{{
                          project.societe.raison.substring(0, 20) + "..."
                        }}</router-link>
                      </small>
                      
                      <small class="td-table" v-bind="attrs" v-on="on"></small>
                    </template>
                    <span>{{ project.societe.raison }}</span>
                  </v-tooltip>
                  <small class="td-table" v-else>
                    <router-link :to="`/detail-societe/${project.societe.id}`">{{
                      project.societe.raison
                    }}</router-link>
                  </small><br>
                  <small>{{ project.societe.siret }}</small>
                </td>
                <td class="text-left">
                  <div style="max-width: 200px">
                    <router-link :to="`/detail-compteur/${project.Compteur[0].id}`">
                      <small>{{ project.Compteur[0].numCompteur }}</small>
                    </router-link>
                    <v-icon
                      v-if="project.Compteur.length > 1"
                      data-toggle="collapse"
                      :data-target="'#multiCollapseCompteur' + index"
                      aria-expanded="false"
                      :aria-controls="'multiCollapseCompteur' + index"
                      right
                    >
                      mdi-menu-down
                    </v-icon>
                    <div
                      class="collapse multi-collapse"
                      :id="'multiCollapseCompteur' + index"
                    >
                      <div v-for="c in project.Compteur">
                        <router-link :to="`/detail-compteur/${c.id}`">
                          <small>
                            {{ c.numCompteur }}
                          </small>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left">
                  <div style="max-width: 200px">
                    <div v-if="project.ACD.length > 0">
                      <small>
                        <a :href="project.ACD[0]" target="_blank"
                          ><span class="text-body-4 blue--text">ACD 1</span></a
                        >
                      </small>
                      <v-icon
                        v-if="
                          project.ACD.length > 1 ||
                          project.Facture.length > 0 ||
                          project.Contrat.length > 0
                        "
                        data-toggle="collapse"
                        :data-target="'#multiCollapseFile' + index"
                        aria-expanded="false"
                        :aria-controls="'multiCollapseFile' + index"
                      >
                        mdi-menu-down
                      </v-icon>
                    </div>
                    <div v-else-if="project.Facture.length > 0">
                      <small>
                        <a :href="project.Facture[0]" target="_blank"
                          ><span class="text-body-4 blue--text">Facture 1</span></a
                        >
                      </small>
                      <v-icon
                        v-if="
                          project.ACD.length > 0 ||
                          project.Facture.length > 1 ||
                          project.Contrat.length > 0
                        "
                        data-toggle="collapse"
                        :data-target="'#multiCollapseFile' + index"
                        aria-expanded="false"
                        :aria-controls="'multiCollapseFile' + index"
                      >
                        mdi-menu-down
                      </v-icon>
                    </div>
                    <div v-else-if="project.Contrat.length > 0">
                      <small>
                        <a :href="project.Contrat[0]" target="_blank"
                          ><span class="text-body-4 blue--text">Contrat 1</span></a
                        >
                      </small>
                      <v-icon
                        v-if="
                          project.ACD.length > 0 ||
                          project.Facture.length > 0 ||
                          project.Contrat.length > 1
                        "
                        data-toggle="collapse"
                        :data-target="'#multiCollapseFile' + index"
                        aria-expanded="false"
                        :aria-controls="'multiCollapseFile' + index"
                      >
                        mdi-menu-down
                      </v-icon>
                    </div>
                    <div class="collapse multi-collapse" :id="'multiCollapseFile' + index">
                      <div v-for="(acd, index) in project.ACD">
                        <small>
                          <a :href="acd" target="_blank"
                            ><span class="text-body-4 blue--text"
                              >ACD {{ index + 1 }}</span
                            ></a
                          >
                        </small>
                      </div>
                      <div v-for="(Facture, index) in project.Facture">
                        <small>
                          <a :href="Facture" target="_blank"
                            ><span class="text-body-4 blue--text"
                              >Facture {{ index + 1 }}</span
                            ></a
                          >
                        </small>
                      </div>
                      <div v-for="(Contrat, index) in project.Contrat">
                        <small>
                          <a :href="Contrat" target="_blank"
                            ><span class="text-body-4 blue--text"
                              >Contrat {{ index + 1 }}</span
                            ></a
                          >
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{ project.dateDevis.split("-").reverse().join("/") }}</td>
  
                <td>
                  <div class="d-flex flex-column align-items-center btn-group">
                    <div class="d-flex flex-row justify-content-center">
                      <small class="small-status" v-if="project.status == '0'"
                        ><v-icon dense style="color: rgb(46, 116, 245)"
                          >mdi-clock-outline</v-icon
                        ><b class="ml-2">en attente</b></small
                      >
                      <small class="small-status" v-if="project.status == '1'"
                        ><v-icon dense style="color: #00e676"
                          >mdi-check-circle-outline</v-icon
                        ><b class="ml-2">acceptée</b></small
                      >
                      <small class="small-status" v-if="project.status == '2'"
                        ><v-icon dense style="color: #ff0000">mdi-close-circle</v-icon
                        ><b class="ml-2">refusée</b></small
                      >
                      <small class="small-status" v-if="project.status == '3'"
                        ><v-icon dense style="color: #ff0000">mdi-alert-outline</v-icon
                        ><b class="ml-2">erreur technique</b></small
                      >
                      <small class="small-status" v-if="project.status == '4'"
                        ><v-icon dense style="color: orange">mdi-progress-clock</v-icon
                        ><b class="ml-2">en traitement</b></small
                      >
  
                      <!-- new buttons -->
                      <small class="small-status" v-if="project.status == '5'"
                        ><v-icon dense style="color: rgb(45, 139, 36)">mdi-check-all</v-icon
                        ><b class="ml-2">validée</b></small
                      >
                      <small class="small-status" v-if="project.status == '6'"
                        ><v-icon dense style="color: rgb(182, 58, 8)">mdi-cancel</v-icon
                        ><b class="ml-2">annulée</b></small
                      >
                      <small class="small-status" v-if="project.status == '7'"
                        ><v-icon dense style="color: rgb(109, 106, 109)"
                          >mdi mdi-lock-clock</v-icon
                        ><b class="ml-2">acd primer</b></small
                      >
                      <small class="small-status" v-if="project.status == '8'"
                        ><v-icon dense style="color: rgb(45, 120, 36)">mdi-check-all</v-icon
                        ><b class="ml-2"
                          >acceptée (compteur <br />n'est pas téléopérable!)</b
                        ></small
                      >
                      <small class="small-status" v-if="project.status == '9'"
                        ><v-icon dense style="color: rgb(45, 120, 36)">mdi-check-all</v-icon
                        ><b class="ml-2">Aucune mesure trouvée sur ce point.</b></small
                      >
                      <small class="small-status" v-if="project.status == '10'"
                        ><v-icon dense style="color: rgb(45, 120, 36)">mdi-check-all</v-icon
                        ><b class="ml-2">PDL inexistant ou invalide.</b></small
                      >
                      <small class="small-status" v-if="project.status == '11'"
                        ><v-icon dense style="color: rgb(45, 120, 36)">mdi-check-all</v-icon
                        ><b class="ml-2">Courbe de charge incomplète.</b></small
                      >
                      <div class="d-flex flex-row" v-if="showActionsBtns(project.status)">
                        <v-icon
                          class="dropdown-toggle"
                          data-toggle="collapse"
                          :data-target="'#collapseStatut' + index"
                          aria-expanded="false"
                          :aria-controls="'collapseStatut' + index"
                          size="20"
                          left
                        >
                          mdi-menu-down
                        </v-icon>
                      </div>
                    </div>
                    <div
                      class="collapse"
                      :id="'collapseStatut' + index"
                      style="border: 0px"
                      v-if="showActionsBtns(project.status)"
                    >
                      <div class="d-flex flex-row mt-4 mt-lg-2">
                        <!-- refuser -->
                        <v-btn
                          title="refuser la demande de cotation"
                          @click="
                            ActionsBtns(
                              project.id,
                              '2',
                              `Vous êtes sur le point de refuser cette demande de cotation!`
                            )
                          "
                          class="dropdown-project"
                          v-if="
                            $store.getters.hasPermissionByStatus(
                              'DC',
                              project.status,
                              'refusee'
                            )
                          "
                          icon
                          small
                          plain
                        >
                          <v-icon color="red"> mdi-close-circle </v-icon>
                        </v-btn>
                        <!-- annuler -->
                        <v-btn
                          title="annuler demande de cotation"
                          @click="
                            ActionsBtns(
                              project.id,
                              '6',
                              `Vous êtes sur le point d'annuler cette demande de cotation!`
                            )
                          "
                          class="dropdown-project"
                          v-if="
                            $store.getters.hasPermissionByStatus(
                              'DC',
                              project.status,
                              'annuler'
                            )
                          "
                          icon
                          small
                          plain
                        >
                          <v-icon color="red accent-5"> mdi-cancel </v-icon>
                        </v-btn>
                        <!-- accepter -->
                        <v-btn
                          title="accepter la demande de cotation"
                          @click="
                            ActionsBtns(
                              project.id,
                              '1',
                              `Vous êtes sur le point d'accepter cette demande de cotation!`
                            )
                          "
                          v-if="
                            $store.getters.hasPermissionByStatus(
                              'DC',
                              project.status,
                              'valider'
                            )
                          "
                          class="dropdown-project"
                          icon
                          small
                          plain
                        >
                          <v-icon color="green accent-3"> mdi-check-circle </v-icon>
                        </v-btn>
                        <!-- créer un devis -->
                        <v-btn
                          title="créer un devis"
                          @click="CreerUnDevis(project.societe.raison)"
                          class="dropdown-project"
                          v-if="
                            $store.getters.hasPermissionByStatus(
                              'DC',
                              project.status,
                              'create_devis'
                            )
                          "
                          icon
                          small
                          plain
                        >
                          <v-icon color="green accent-5">
                            mdi-receipt-text-plus-outline
                          </v-icon>
                        </v-btn>
                        <!-- ac primer -->
                        <v-btn
                          title="acd_primer"
                          @click="
                            ActionsBtns(
                              project.id,
                              '7',
                              `Vous êtes sur le point de mettre cette demande de cotation en status acd pirimé!`
                            )
                          "
                          class="dropdown-project"
                          v-if="
                            $store.getters.hasPermissionByStatus(
                              'DC',
                              project.status,
                              'acd_primer'
                            )
                          "
                          icon
                          small
                          plain
                        >
                          <v-icon color="grey accent-5"> mdi mdi-lock-clock </v-icon>
                        </v-btn>
                        <!-- estimation -->
                        <v-btn
                          title="estimation"
                          @click="openModalEstimation(project.Compteur[0],project.id)"
                          class="dropdown-project"
                          v-if="
                            $store.getters.hasPermissionByStatus(
                              'DC',
                              project.status,
                              'estimation'
                            )
                          "
                          icon
                          small
                          plain
                        >
                          <v-icon color="purple"> mdi mdi-creation </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <noDataTable
              class="ma-4"
              v-if="projects.length == 0 && loadingTable == false"
            />
            <progressCircular class="ma-8" v-if="loadingTable == true" />
          </template>
        </datatable>
      </div>
      <div
        class="d-flex flex-row justify-content-center justify-sm-start align-items-center"
      >
        <pagination
          v-if="projects.length > 0"
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import noDataTable from "../../components/dataTable/no-data-table.vue";
import progressCircular from "../../components/dataTable/progress-circular.vue";
import axios from "@/plugins/axios";
import breadcrumb from "@/components/breadcrumb.vue";

export default {
  name: "MonDemandeDeCotation",
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
    breadcrumb,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "14vw", label: "Date d'ajout", name: "date", sortable: true },
      { width: "14vw", label: "Commercial", name: "Commerciale", sortable: true },
      { width: "14vw", label: "Raison Sociale", name: "Raison", sortable: true },
      { width: "14vw", label: "Compteur", name: "numCompteur", sortable: true },
      { width: "14vw", label: "Pièce jointe", sortable: true },
      { width: "14vw", label: "Début du devis", name: "dateDebut", sortable: true },
      { width: "14vw", label: "Statut", name: "status", sortable: true },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      loadingTable: false,
      listNavs: [
        {
          name: "Cotation",
          link: "#",
        },
        {
          name: "Mes demandes de cotations",
          link: "#",
        },
      ],
      filterStatus: "tout",
      projects: [],
      columns: columns,
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "20", "30"],
      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
    };
  },
  async created() {
    this.loadingTable = true;
    await this.getData();
    this.loadingTable = false;
  },
  computed: {
    roleUser() {
      return this.$store.getters.getUserRole;
    },
    user() {
      return this.$store.getters.getUser;
    },
    showActionsBtns() {
      return (status) => {
        return (
          this.$store.getters.hasPermissionByStatus("DC", status, "valider") ||
          this.$store.getters.hasPermissionByStatus("DC", status, "refusee") ||
          this.$store.getters.hasPermissionByStatus("DC", status, "create_devis") ||
          this.$store.getters.hasPermissionByStatus("DC", status, "annuler") ||
          this.$store.getters.hasPermissionByStatus("DC", status, "acd_primer")
        );
      };
    },
  },
  methods: {
    filterByStatus() {
      var pageNumber = 1;
      if (this.filterStatus != "tout") {
        this.tableData.statusFilter = this.filterStatus;
        this.getData(1);
      } else {
        this.tableData.statusFilter = "";
        this.getData(1);
      }
    },
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getMyDemandeDeCotation/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    CreerUnDevis(raisonSocial) {
      this.$router.push({
        path: `/Devis/effectuerUnDevis`,
        query: { raisonSocial: raisonSocial },
      });
    },
    CreerUneVisteTechnique(idDC) {
      this.$router.push({ path: "/visite-technique/nouveau-formulaire" });
    },
    CreerUneVisteInstallation(idDC) {
      this.$router.push({ path: "/visite-installation/ajoutVisiteInstallation" });
    },
    async getData(pageNumber = 1) {
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getMyDemandeDeCotation/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;

            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    ActionsBtns(id, status, actionMessage) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: actionMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = true;
          this.loading = true;
          this.error = false;
          var formData = new FormData();
          formData.append("id_dc", id);
          formData.append("status", status);
          formData.append("token", this.$store.getters.getUserToken);
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "changeDemandeCotationStatus/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.status == 200) {
                this.getData(this.pagination.currentPage);
                console.log(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    spliteList(list) {
      return list.split(",");
    },
    spliteListSlice(list) {
      return list.split(",").slice(1);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<style scoped>
#table-validation-cotation th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 400px) {
  .container-div {
    width: 125px !important;
    padding: 0px;
    margin: 0;
  }
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  @import "../../components/dataTable/style.css";
  #table-validation-cotation td:nth-of-type(1):before {
    content: "Date d'ajout";
  }
  #table-validation-cotation td:nth-of-type(2):before {
    content: "Commercial";
  }
  #table-validation-cotation td:nth-of-type(3):before {
    content: "Raison Sociale";
  }
  #table-validation-cotation td:nth-of-type(4):before {
    content: "Energie";
  }
  #table-validation-cotation td:nth-of-type(5):before {
    content: "Compteur";
  }
  #table-validation-cotation td:nth-of-type(6):before {
    content: "Pièce jointe";
  }
  #table-validation-cotation td:nth-of-type(7):before {
    content: "Début de contrat";
  }
  #table-validation-cotation td:nth-of-type(8):before {
    content: "Statut";
  }
}
.small-status {
  width: max-content;
}
</style>
